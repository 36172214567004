<script setup>
import { transl } from "@/composables/useTranslation";

const props = defineProps({
  category: {
    type: String,
    default: "all",
  },
  categories: {
    type: Array,
    default: [],
  },
});

const emit = defineEmits(['update:category'])

const activeElem = ref(null);

function selectCategory(category) {
  activeElem.value = category;
  emit("update:category", category);
}

function isSelected(categoryValue) {
  return activeElem.value == categoryValue;
}

function watchProps() {
  watch(
    () => props.category,
    () => {
      activeElem.value = props.category;
    },
    { immediate: true }
  );
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <div class="category">
    <div
      class="category-item"
      @click="selectCategory('all')"
      :class="{ active: isSelected('all') }"
    >
      {{ transl("Texts:All") }}
    </div>
    <div
      class="category-item"
      :class="{ active: isSelected(category) }"
      @click="selectCategory(category)"
      v-for="category in categories"
    >
      {{ transl(`Texts:${category}`) }}
    </div>
  </div>
</template>
<style scoped>
.category {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.category-item {
  padding: 12px 17px;
  font-weight: 600;
  cursor: pointer;
}

.category-item.active {
  color: #ed9819;
  position: relative;
}
.category-item.active:before {
  content: " ";
  position: absolute;
  width: 100%;
  bottom: 0;
  top: auto;
  left: 0;
  height: 5px;
  background: linear-gradient(180deg, #ffb649, #e78f0c);
  border-radius: 10px;
}

@media (hover: hover) {
  .logo:hover {
    cursor: pointer;
  }
  .category-item:hover {
    cursor: pointer;
    background: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
  .more-button:hover {
    opacity: 0.8;
  }
}
@media (hover: none) {
  .category-item:active {
    cursor: pointer;
    background: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
}

@media (hover: none) {
  .category-item:active {
    cursor: pointer;
    background: rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
}
</style>
~/composables/useTranslation